import React from "react";
import { Sheet, SheetContent, SheetTrigger } from "../ui/sheet";
import { Button } from "../ui/button";
import { Menu, ShoppingCart } from "lucide-react";
import { indexStore } from "../../store/indexStore";
import { LuGlobe } from "react-icons/lu";
import LangSelect from "../langSelect/langSelect";
import { Link, useParams } from "react-router-dom";
import { GrOrderedList } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import Visibility from "../utils/Visibility";
import { DarkModeTogle } from "../langSelect/DarkModeTogle";

function SideSheet(props) {
  const { lang, changeLangBottomSheet } = indexStore();
  const params = useParams();
  const { t } = useTranslation();
  const qantity = JSON.parse(
    localStorage.getItem("cart" + params.branch_id)
  )?.reduce((acc, item) => acc + item.amount, 0);
  const tokenOrder = localStorage.getItem("tokenOrder" + params.branch_id);
  return (
    <div className=" flex justify-end bg-transparent">
      <Sheet>
        <SheetTrigger asChild>
          <Button
            variant="castoum"
            className={` bg-transparent border-none text-lg cursor-pointer text-onBackground ${
              props.backgroundColorfull ? "bg-primary text-onPrimary" : " "
            }`}
            size="icon"
          >
            <Menu className="h-5 w-5" />
          </Button>
        </SheetTrigger>
        <SheetContent
          side={lang === "ar" ? "right" : "left"}
          className="flex flex-col"
        >
          <div className="text-lg font-semibold flex justify-center">
            {t("gloabl.Settings")}
          </div>
          <nav className="flex flex-col gap-3">
            <div
              className={`text-onBackground gap-2 flex  items-center p-2 cursor-pointer ${
                props.backgroundColorfull ? "bg-primary text-onPrimary" : " "
              }`}
              onClick={() => changeLangBottomSheet(true)}
            >
              <LuGlobe className="text-xl font-semibold" />
              <span className="text-xl font-semibold">
                {lang === "ar" ? "ع" : "EN"}
              </span>
            </div>
            <DarkModeTogle />
            <Visibility visible={params.branch_id}>
              <Link
                className="flex p-2 gap-2 items-center "
                to={`/${params.domain}/${params.branch_id}/cart`}
              >
                <div className="relative">
                  <span className="absolute -top-5 -right-2 bg-secondary  text-onPrimary p-1 rounded-50H">
                    {qantity}
                  </span>
                  <ShoppingCart className="text-xl font-semibold" />
                </div>
                <span className="text-xl font-semibold">
                  {t("gloabl.Cart")}
                </span>
              </Link>
            </Visibility>

            <Visibility visible={tokenOrder && params.branch_id}>
              <Link
                className="flex p-2 gap-2 items-center"
                to={`/${params.domain}/${params.branch_id}/orders`}
              >
                <GrOrderedList className="text-xl font-semibold" />
                <span className="text-xl font-semibold">
                  {t("gloabl.orders")}
                </span>
              </Link>
            </Visibility>
          </nav>
          <LangSelect />
        </SheetContent>
      </Sheet>
    </div>
  );
}

export default SideSheet;
