import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import BottomSheet from "../ButtomSheet/BottomSheet";
import Visibility from "../utils/Visibility";
import BranchInfo from "./BranchInfo";
import SocialMedia from "./SocialMedia";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../ui/sheet";
import { indexStore } from "../../store/indexStore";

const Details = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const { style } = indexStore();

  return (
    // <BottomSheet
    //   toggleOpen={(value) => setOpen(value)}
    //   title={t("gloabl.details")}
    //   open={open}
    //   button={
    //     <div
    //       className="cursor-pointer p-1 w-full rounded-lg text-center bg-background text-onBackground"
    //       onClick={showDrawer}
    //     >
    //       {t("gloabl.details")}
    //     </div>
    //   }
    // >
    <Sheet>
      <SheetTrigger asChild>
        <div
          className="cursor-pointer p-1 w-full rounded-lg text-center bg-background text-onBackground"
          onClick={showDrawer}
        >
          {t("gloabl.details")}
        </div>
      </SheetTrigger>
      <SheetContent
        side={"bottom"}
        style={{ background: style.background }}
        className="flex flex-col bg-background max-h-[60vh] overflow-y-auto z-50 "
      >
        <SheetHeader>
          <SheetTitle>
            <h2 className="text-xl font-bold mb-4">{t("gloabl.details")}</h2>
          </SheetTitle>
        </SheetHeader>
        <div className="flex flex-col">
          <BranchInfo branch={props.branch} />
          <Visibility visible={props.social && props.social.length !== 0}>
            <SocialMedia social={props.social} />
          </Visibility>
        </div>
      </SheetContent>
    </Sheet>

    // </BottomSheet>
  );
};
export default Details;
