import React from "react";
import Layout from "../Layout";
import { useTranslation } from "react-i18next";
import Iterator from "../../utils/Iterator";

function TermsOfService() {
  const { t } = useTranslation();
  const terms = Terms(t);
  const renderTerms = (e, i) => (
    <div key={i} className="my-3">
      <h4 className="text-xl font-bold my-3">{e.name}</h4>
      <p className="text-lg my-3 whitespace-pre-line">{e.description}</p>
    </div>
  );
  return (
    <Layout title={t("Terms.title")}>
      <div className="px-5 py-7">
        <h2 className="text-4xl font-bold text-center py-6">
          {" "}
          {t("Terms.title")}
        </h2>
        <h4 className="text-xl my-3">{t("Terms.updateDate")}</h4>
        <h4 className="text-xl my-3">{t("Terms.company")}</h4>
        <Iterator data={terms} render={renderTerms} />
      </div>
    </Layout>
  );
}

export default TermsOfService;
const Terms = (t) => [
  {
    name: "",
    description: t("Terms.introduction"),
  },
  {
    name: t("Terms.PrivacyandCommunicationsName"),
    description: t("Terms.PrivacyandCommunicationsDes"),
  },
  {
    name: t("Terms.AbilitytoAcceptTermsofServiceName"),
    description: t("Terms.AbilitytoAcceptTermsofServiceDes"),
  },
  {
    name: t("Terms.AcceptableUseandConductName"),
    description: t("Terms.AcceptableUseandConductDes"),
  },
  {
    name: t("Terms.PurchasesName"),
    description: t("Terms.PurchasesDes"),
  },
  {
    name: t("Terms.AvailabilityErrorsandInaccuraciesName"),
    description: t("Terms.AvailabilityErrorsandInaccuraciesDes"),
  },

  {
    name: t("Terms.SubscriptionsName"),
    description: t("Terms.SubscriptionsDes"),
  },
  {
    name: t("Terms.FreeTrialName"),
    description: t("Terms.FreeTrialDes"),
  },
  {
    name: t("Terms.FeeChangesName"),
    description: t("Terms.FeeChangesDes"),
  },
  {
    name: t("Terms.ChargebackRefundPolicyName"),
    description: t("Terms.ChargebackRefundPolicyDes"),
  },
  {
    name: t("Terms.PaymentProcessingName"),
    description: t("Terms.PaymentProcessingDes"),
  },
  {
    name: t("Terms.ContentName"),
    description: t("Terms.ContentDes"),
  },
  {
    name: t("Terms.AccountsName"),
    description: t("Terms.AccountsDes"),
  },
  {
    name: t("Terms.IntellectualPropertyName"),
    description: t("Terms.IntellectualPropertyDes"),
  },
  {
    name: t("Terms.TerminationName"),
    description: t("Terms.TerminationDes"),
  },
  {
    name: t("Terms.DisclaimerName"),
    description: t("Terms.DisclaimerDes"),
  },
  {
    name: t("Terms.LimitationofLiabilityName"),
    description: t("Terms.LimitationofLiabilityDes"),
  },
  {
    name: t("Terms.GoverningLawName"),
    description: t("Terms.GoverningLawDes"),
  },
  {
    name: t("Terms.ChangesName"),
    description: t("Terms.ChangesDes"),
  },
  {
    name: t("Terms.ContactName"),
    description: t("Terms.ContactDes"),
  },
];
