import React from "react";
import Layout from "../Layout";
import Iterator from "../../utils/Iterator";
import { useTranslation } from "react-i18next";

function PrivacyPolicy() {
  const { t } = useTranslation();
  const Privacy = Privacies(t);
  const renderPrivacy = (e, i) => (
    <div key={i} className="my-3">
      <h4 className="text-xl font-bold my-3">{e.name}</h4>
      <p className="text-lg my-3 whitespace-pre-line">{e.description}</p>
    </div>
  );
  return (
    <Layout title={t("PrivacyPolicy.title")}>
      <div className="px-5 py-7">
        <h2 className="text-4xl font-bold text-center py-6">
          {" "}
          {t("PrivacyPolicy.title")}
        </h2>
        <h4 className="text-xl my-3">{t("PrivacyPolicy.updateDate")}</h4>
        <h4 className="text-xl my-3">{t("PrivacyPolicy.company")}</h4>
        <Iterator data={Privacy} render={renderPrivacy} />
      </div>
    </Layout>
  );
}

export default PrivacyPolicy;

const Privacies = (t) => [
  {
    name: "",
    description: t("PrivacyPolicy.introduction"),
  },
  {
    name: t("PrivacyPolicy.InformationWeAutomaticallyCollectName"),
    description: t("PrivacyPolicy.InformationWeAutomaticallyCollectDes"),
  },
  {
    name: t("PrivacyPolicy.InformationYouChoosetoSubmitName"),
    description: t("PrivacyPolicy.InformationYouChoosetoSubmitDes"),
  },
  {
    name: t("PrivacyPolicy.TransactionInformationName"),
    description: t("PrivacyPolicy.TransactionInformationDes"),
  },
  {
    name: t("PrivacyPolicy.HowWeUseTheInformationName"),
    description: t("PrivacyPolicy.HowWeUseTheInformationDes"),
  },
  {
    name: t("PrivacyPolicy.CookiesTrackingName"),
    description: t("PrivacyPolicy.CookiesTrackingDes"),
  },

  {
    name: t("PrivacyPolicy.InformationSharingPracticeName"),
    description: t("PrivacyPolicy.InformationSharingPracticeDes"),
  },
  {
    name: t("PrivacyPolicy.OptOutFromCommunicationsName"),
    description: t("PrivacyPolicy.OptOutFromCommunicationsDes"),
  },
  {
    name: t("PrivacyPolicy.ModifyingAndDeletingYourPersonalDataName"),
    description: t("PrivacyPolicy.ModifyingAndDeletingYourPersonalDataDes"),
  },
  {
    name: t("PrivacyPolicy.ExercisingDataProtectionRightsName"),
    description: t("PrivacyPolicy.ExercisingDataProtectionRightsDes"),
  },
  {
    name: t("PrivacyPolicy.ConsentToTransferName"),
    description: t("PrivacyPolicy.ConsentToTransferDes"),
  },
  {
    name: t("PrivacyPolicy.RetentionofDataName"),
    description: t("PrivacyPolicy.RetentionofDataDes"),
  },
  {
    name: t("PrivacyPolicy.ChildrenName"),
    description: t("PrivacyPolicy.ChildrenDes"),
  },
  {
    name: t("PrivacyPolicy.CaliforniaResidentsPrivacyRightsName"),
    description: t("PrivacyPolicy.CaliforniaResidentsPrivacyRightsDes"),
  },
  {
    name: t("PrivacyPolicy.ChangesName"),
    description: t("PrivacyPolicy.ChangesDes"),
  },
  {
    name: t("PrivacyPolicy.DataSecurityName"),
    description: t("PrivacyPolicy.DataSecurityDes"),
  },
  {
    name: t("PrivacyPolicy.HowtoContactUsName"),
    description: t("PrivacyPolicy.HowtoContactUsDes"),
  },
];
