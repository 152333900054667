export const createAppSlice = (set) => ({
  lang: "",
  name: "",
  description: "",
  categoryId: 0,
  langOpenBottomSheet: false,
  displayWaygrid: true,
  changeLang: (lang) => {
    localStorage.setItem("Lang", lang);
    set((state) => ({
      ...state,
      lang: lang,
      name: lang === "ar" ? "name_ar" : "name_en",
      description: lang === "ar" ? "description_ar" : "description_en",
    }));
  },
  changeCategory: (id) => {
    set((state) => ({ ...state, categoryId: id }));
  },
  changeLangBottomSheet: (value) => {
    set((state) => ({ ...state, langOpenBottomSheet: value }));
  },
  changeDisplayWaygrid: (value) => {
    set((state) => ({ ...state, displayWaygrid: value }));
  },
});
