import { create } from "zustand";
import { createAppSlice } from "./createAppSlice";
import { createMenuSlice } from "./createMenuSlice";
import { createOrdersSlice } from "./createOrdersSlcie";

export const indexStore = create((...a) => ({
  ...createAppSlice(...a),
  ...createMenuSlice(...a),
  ...createOrdersSlice(...a),
}));
