import React from "react";
import Iterator from "../utils/Iterator";
import { useTranslation } from "react-i18next";
import { socialMediaTypes } from "../../enum/socialMediaType";
import { indexStore } from "../../store/indexStore";

function SocialMedia(props) {
  const { t } = useTranslation();
  const { style } = indexStore();
  const renderSocialMedia = (e, i) => {
    if (!e.value.startsWith("https://") && !e.value.startsWith("http://")) {
      e.value = "https://" + e.value;
    }
    const classNameP=`flex gap-2 items-center font-semibold text-lg capitalize`
    const classNameIcon=`bg-primary text-onPrimary p-2 rounded-md text-xl font-bold` 
const styleSpan={color:style.onPrimary,background:style.primary}

    return (
      <div className={classNameP}>
        <span style={styleSpan} className={classNameIcon}> {socialMediaTypes[e.type]}</span>
        <span className="">
          <a target="_blank" rel="noreferrer" href={e.value}>
            {e.name}
          </a>
        </span>
      </div>
    );
  };
  return (
    <div className="py-2 flex flex-col gap-3 text-onBackground">
      <p className="text-lg font-bold "> {t("gloabl.connectUs")}:</p>
      <Iterator data={props.social} render={renderSocialMedia} />
    </div>
  );
}

export default SocialMedia;
