import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function FormContactUs() {
  const { t } = useTranslation();
  const styleLFild = "w-full border-2 border-gray-300 rounded-md p-2";
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    const mailtoLink = `mailto:info@twhmenu.com?subject=${encodeURIComponent(
      subject
    )}&body=Name: ${encodeURIComponent(name)}%0APhone: ${encodeURIComponent(
      phone
    )}%0AMessage: ${encodeURIComponent(message)}`;

    window.location.href = mailtoLink;
  };
  return (
    <div className="flex flex-col w-full gap-5">
      <h3 className="text-xl font-bold">{t("contact.sendMessage")}</h3>
      <form className="flex flex-col gap-5" onSubmit={handleSubmit}>
        <input
          type="text"
          className={styleLFild}
          placeholder={t("contact.name")}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          type="text"
          className={styleLFild}
          placeholder={t("contact.phone")}
          onChange={(e) => setPhone(e.target.value)}
          required
        />
        <input
          type="text"
          className={styleLFild}
          placeholder={t("contact.subject")}
          onChange={(e) => setSubject(e.target.value)}
          required
        />

        <textarea
          className={styleLFild}
          placeholder={t("contact.message")}
          cols="30"
          rows="10"
          onChange={(e) => setMessage(e.target.value)}
          required
        ></textarea>
        <button type="submit" className="bg-black text-white rounded-md p-2">
          {t("contact.send")}
        </button>
      </form>
    </div>
  );
}

export default FormContactUs;
