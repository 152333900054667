import orderServices from "../api-service/order-service";

export const createOrdersSlice = (set) => ({
  orders: [],
  order: {},
  ordersLoading: false,
  orderLoading: false,
  errorCode: null,
  fetchOrdersAction: async (params) => {
    set({ ordersLoading: true });
    try {
      const res = await orderServices.getOrders(params);
      set({ orders: res.data.data, ordersLoading: false });
      return res.data.data;
    } catch (error) {
      set({ errorCode: error.status, ordersLoading: false });
    }
  },
  showOrderAction: async (params) => {
    set({ orderLoading: true });
    try {
      const res = await orderServices.showOrder(params);
      set({ order: res.data.data, orderLoading: false });
      return res.data.data;
    } catch (error) {
      set({ errorCode: error.status, orderLoading: false });
    }
  },
  updateCartQuantity: (quantity) => {
    set({ cartQuantity: quantity });
  },
});
