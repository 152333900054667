import React, { useEffect } from "react";
import { indexStore } from "../store/indexStore";

const Tabs = (props) => {
  useEffect(() => {
    if (props.data.length !== 0) {
      const activeElement = document.querySelector(".cat-active");

      if (activeElement) {
        activeElement.scrollIntoView({
          behavior: "smooth",
          inline: "center",
          block: "nearest",
        });
      }
    }
  }, [props.onChange, props.data]);
  return (
    <div className="flex gap-3 flex-nowrap overflow-x-auto py-1">
      {props.data &&
        props.onChange &&
        props.data.map((t, i) => (
          <Tab
            key={i}
            data={t}
            onClick={() => props.onChange(i, t.id)}
            active={t.id === props.value}
          />
        ))}
    </div>
  );
};

const Tab = (props) => {
  const { name } = indexStore();
  return (
    <div
      className={`p-3 shadow-cat-shadow rounded-md 
        cursor-pointer duration-200 ease-out text-base flex 
        gap-3 items-center whitespace-nowrap bg-background text-onBackground  ${
          props.active ? "shadow-none bg-primary text-onPrimary cat-active" : ""
        }`}
      onClick={props.onClick}
    >
      {props.data[name]}
    </div>
  );
};

export default Tabs;
