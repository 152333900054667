import { axiosInstance } from "../axios-instance";

const orderServices = {
  requestOtp: (payload) => axiosInstance().post(`sendOtp`, payload),
  sendOtp: (payload) => axiosInstance().post(`verifyOtp`, payload),
  storeOrder: (payload) =>
    axiosInstance().post(`storeOrder`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${payload.token}`,
      },
    }),
  getOrders: (payload) =>
    axiosInstance().get(`order/getOrders`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${payload.token}`,
      },
    }),
  showOrder: (payload) =>
    axiosInstance().get(`/order/show/${payload.order_id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${payload.token}`,
      },
    }),
};

export default orderServices;
