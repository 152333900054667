import React from "react";

function MenuCircle({ element, name, props, IMG_URl }) {
  return (
    <div
      key={element.id}
      className={`relative flex flex-col items-center gap-1 cursor-pointer `}
      onClick={() =>
        element.id !== props.selectedMenu ? props.onClickMenu(element.id) : null
      }
    >
      <img
        alt={element.name_en}
        src={`${IMG_URl}${element.image}`}
        className={`max-w-24  w-24 h-24 rounded-50H object-cover ${
          props.selectedMenu ? "max-w-16 !w-16 h-16 max-h-16" : ""
        }`}
      />
      <p
        className={`text-sm font-bold text-center text-onPrimary ${
          props.selectedMenu ? "text-xs !text-onBackground" : ""
        } ${element.id == props.selectedMenu ? "!text-primary" : ""}`}
      >
        {element[name]}
      </p>
    </div>
  );
}

export default MenuCircle;
